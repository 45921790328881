<template>
  <v-container
    id="login"
    fill-height
    fluid
    class="flex-column justify-center align-center pa-0"
  >
    <!-- <v-scroll-y-reverse-transition>
      <div 
        v-if="view.scene.logo.show"
        class="logo-container d-flex align-center justify-start"
      >
        <icon-base
          v-if="breakpoint('xs')"
          width="20"
          height="24"
          icon-name="hive"
          class="hive mr-3 white--text"
        >
          <brand-symbol />
        </icon-base>
        <icon-base
          width="126"
          height="26"
          icon-name="Mobees"
          class="logo white--text"
        >
          <brand-logo />
        </icon-base>
      </div>
    </v-scroll-y-reverse-transition> -->
    <v-scroll-y-reverse-transition>
      <v-sheet
        v-if="view.scene.container.show"
        :width="breakpoint('xs') ? '420px' : '100%'"
        :height="breakpoint('xs') ? 'auto' : '100%'"
        color="transparent"
        dark
        class="login-container pa-12 py-8 d-flex flex-column justify-center rounded-lg"
      >
        <!-- <v-fade-transition> -->
        <h1 
          class="text-h3 accent--text font-weight-black text-uppercase my-6"
        >
          <!-- <v-img 
            src="/img/icons/com-app-icon.svg" 
            max-width="52px"
            class="app-logo d-inline-block mb-n2 mr-1"
          /> -->
          <icon-base
            width="20"
            height="24"
            icon-name="hive"
            class="hive mr-3 mb-n2 white--text"
          >
            <brand-symbol />
          </icon-base>
          {{ $tc('general.admin_app') }}
        </h1>
        <h2
          class="text-h4 text--off font-weight-light mb-8"
        >
          {{ $tc('general.admin_app', 0) }}
        </h2>
        <!-- </v-fade-transition> -->
        <v-fade-transition>
          <v-form
            v-model="valid"
            :lazy-validation="lazy"
            class="login-form"
            ref="form"
          >
            <v-text-field
              v-model="username.input"
              :rules="username.rules"
              :loading="username.loading"
              :disabled="username.disabled"
              hide-details
              :label="$tc('general.user')"
              required
              class="control mb-4"
              name="username"
              ref="username"
              :autofocus="true"
              @keyup.native="enableSubmit"
              @keyup.enter="handleForm"
              @focus="enableSubmit"
              outlined
            />

            <v-expand-transition>
              <v-text-field
                v-show="user.status=='TODO'"
                v-model="newPassword.new"
                :append-icon="newPassword.visible ? icons.mdiEye : icons.mdiEyeOff"
                :type="newPassword.visible ? 'text' : 'password'"
                :disabled="newPassword.disabled"
                @click:append="newPassword.visible = !newPassword.visible"
                @keyup.native="enableSubmit"
                :label="$t('login.create_password')"
                required
                class="control mb-4"
                name="newPassword"
                ref="newPassword"
                outlined
              />
            </v-expand-transition>
            <v-expand-transition>
              <v-text-field
                v-show="user.status=='TODO'"
                v-model="newPassword.confirm"
                :append-icon="newPassword.visible ? icons.mdiEye : icons.mdiEyeOff"
                :type="newPassword.visible ? 'text' : 'password'"
                :disabled="newPassword.disabled"
                @click:append="newPassword.visible = !newPassword.visible"
                @keyup.native="enableSubmit"
                :label="$t('login.confirm_password')"
                required
                class="control"
                name="confirmPassword"
                ref="confirmPassword"
                outlined
              />
            </v-expand-transition>
            <v-expand-transition>
              <v-checkbox
                v-show="user.status=='TODO'"
                v-model="views.tos.accepted"
                @change="enableSubmit"
              >
                <span 
                  slot="label" 
                  class="body-2"
                >
                  Li e aceito os 
                  <a
                    href="#"
                    @click.stop="views.tos.toggle = true"
                  >
                    Termos e Condições de Uso
                  </a>
                </span>
              </v-checkbox>
            </v-expand-transition>

            <v-expand-transition>
              <v-text-field
                v-model="password.input"
                :append-icon="password.visible ? icons.mdiEye : icons.mdiEyeOff"
                :type="password.visible ? 'text' : 'password'"
                :disabled="password.disabled"
                hide-details
                @click:append="password.visible = !password.visible"
                @keyup.enter="handleForm"
                :label="$t('login.password')"
                required
                class="control mb-4"
                name="password"
                ref="password"
                outlined
              />
            </v-expand-transition>

            <v-expand-transition>
              <v-text-field
                v-show="otp.toggle"
                v-model="otp.input"
                type="text"
                :disabled="otp.disabled"
                hide-details
                :label="$t('login.OTP')"
                required
                class="control mb-4"
                name="otp"
                ref="otp"
                outlined
                @keyup.enter="handleForm"
              />
            </v-expand-transition>

            <v-card-actions class="pa-0">
              <v-spacer />
              <v-btn
                :disabled="submit.disabled"
                :elevation="0"
                :loading="submit.loading"
                fab
                color="accent"
                @click="handleForm"
                class="control px-4"
              >
                <!-- {{ submit.text }} -->
                <v-icon>{{ icons.next }}</v-icon>
              </v-btn>
            </v-card-actions>

            <!-- <v-btn 
              text 
              @click="views.forgotPassword.toggle = true;"
            >
              Esqueceu a senha?
            </v-btn> -->
          </v-form>
        </v-fade-transition>
      </v-sheet>
    </v-scroll-y-reverse-transition>

    <forgot-password />

    <v-snackbar
      v-model="views.forgotPassword.feedback.toggle"
      :timeout="views.forgotPassword.feedback.timeout"
      :multi-line="true"
    >
      {{ views.forgotPassword.feedback.text }}
      <v-btn
        text
        v-if="views.forgotPassword.feedback.button"
        @click="views.forgotPassword.feedback.toggle = false"
      >
        Ok
      </v-btn>
    </v-snackbar>

    <!-- <svg id="mask" height="0" width="0">
      <defs>
        <clipPath id="mask-path">
          <rect width="288" height="96" rx="4" fill="white"/>
        </clipPath>
      </defs>
    </svg> -->
  </v-container>
</template>

<style>

  #login {
    background: var(--mobees-black);
    /* clip-path: url(#mask-path); */
  }
  #mask {
    position: fixed;
    top: 50%;
    left: 50%;
  }

  #login .logo-container {
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 1;
  }
  @media (max-width: 600px) {
    #login .logo-container {
      top: 40px;
      margin-left: 24px;
    }
  }

  #login .hive {
    width: 40px;
  }

  #login .logo {
    height: 20px;
    margin-bottom: 6px;
  }

  #login .login-form {
    width: 100%;
    max-width: 360px;
  }
  .control {
    margin: 16px 0;
  }

  #login .login-container {
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    background-color: rgba(255, 255, 255, 0.96);
  }
  #login .heading {
    font-size: 2.25rem !important;
    line-height: 1.4;
  }

  #login .help-btn {
    text-transform: initial;
    width: 72px;
    height: 72px;
    margin: -24px -24px -24px 0 !important;
  }

  .text--off {
    opacity: .8;
  }
</style>

<script>

  // Icons
  import {
    mdiHelpCircleOutline,
    mdiEye,
    mdiEyeOff,
    mdiClose,
    mdiArrowRight
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { sync } from 'vuex-pathify'
  import { mask } from 'vue-the-mask'


  export default {
    name: 'Login',
    metaInfo: {
      title: 'Login'
    },

    components: {
      IconBase: () => import('@/components/IconBase'),
      BrandSymbol: () => import('@/components/icons/BrandSymbol'),
      // BrandLogo: () => import('@/components/icons/BrandLogo'),
      ForgotPassword: () => import('@/views/login/ForgotPassword'),
    },

    data: () => ({
      icons: {
        mdiHelpCircleOutline,
        mdiEye,
        mdiEyeOff,
        mdiClose,
        next: mdiArrowRight
      },
      view: {
        scene: {
          logo: {
            show: false,
            time: 500,
          },
          container: {
            show: false,
            time: 250,
          },
          heading: {
            show: false,
            time: 250,
          },
          form: {
            show: false,
            time: 250,
          },
        }
      },
      valid: false,
      lazy: true,
      username: {
        input: '',
        loading: false,
        disabled: false,
      },
      password: {
        input: '',
        new: '',
        confirm: '',
        disabled: false,
        loading: false,
        visible: false,
      },
      newPassword: {
        new: '',
        confirm: '',
        rules: [
          v => (!!v && v) === this.newPassword.new || 'A confirmação deve ser igual à senha.'
        ],
        disabled: false,
        loading: false,
        visible: false,
      },
      otp: {
        toggle: false,
        input: null,
        loading: false,
        disabled: false,
      },
      submit: {
        text: 'Próximo',
        loading: false,
        disabled: false
      },
    }),

    computed: {
      views: sync('login/views'),
      user: sync('user/data'),
      // help: sync('help'),
      redirect: sync('app/redirect'),
      toast: sync('app/toast'),

      layoutLarge () {
        var b;
        if(this.user.status=='REA'||this.user.status=='forgotPassword'){
          b = false;
        }else{
          b = true;
        }
        return b;
      },

      layoutHeading (){
        var text;
        // if(this.user.status=='REA'){
        //   text = 'Crie sua senha de acesso abaixo:';
        // }else if(this.user.status=='forgotPassword'){
        //   text = 'Informe seu email abaixo e te enviaremos um link para recuperar a senha.';
        // }
        text = 'Admin'
        return text;
      }
    },

    methods: {
      ...services,
      
      handleForm () {
        if (this.user.status==='TODO') {
          // Create password
          console.log('handle form W/O PASSWORD...')
          this.submit.disabled = this.submit.loading = true;
          this.createPassword(this.username.input, this.newPassword.confirm);
        }else {
          // Login password
          if(this.password.input.length){
            this.submit.disabled = this.submit.loading = true;
            this.login(this.username.input, this.password.input, this.otp.input);
          }else{
            console.log('focus',this.$refs.password.$el);
            this.$refs.password.$el.focus();
          }
        }
      },
      enableSubmit (e) {
        // other validations TODO
        var enable = false;
        switch (this.user.status){
          case 'TODO':
            // var a = this.validateUsername();
            // var b = this.validateNewPassword();
            // var c = this.views.tos.accepted;
            // enable = (a&&b&&c) ? true : false;
            break;
          default:
            enable = true;
        }

        this.submit.disabled = !enable;
      },
      validateUsername () {
        return this.username.input.length===14;
      },
      validateNewPassword () {
        const a = this.newPassword.new===this.newPassword.confirm;
        const b = (this.newPassword.new!='');
        console.log(a&&b);
        return a&&b;
      },
      createPassword (username, password) {
        // Save password on db
        username = username;
        this.$api.COM
          .get('/createpasswordadmin/'+username+'/'+password+'/?format=json')
          .then(response => {
            console.log('create password => ',response);
            this.submit.loading = false;
          })
          .catch(error => {
            console.log('Network/Server Error', error);
            this.toggleToast(
              true,
              this.$t('errors.generic'),
              7000,
              false,
              'error'
            );
            this.submit.disabled = this.submit.loading = false;
          });
      },
      login (username, password, otp) {
        this.$api.COM({
          url: '/authuseradmin',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              useradmin: username,
              password: password,
              otptoken: otp
            }
          })
          .then(response => {
            console.log('login => ',response);
            if (response.data.retorno===206){
              this.otp.toggle = true;
            }else if (response.data.retorno===200){
              const user = Object.assign({}, {
                username: username,
                auth: {
                  token: response.data.authToken,
                  jwt: response.data.jwtToken,
                  timestamp: new Date(),
                  status: true
                },
                roles: _.map(response.data.perfis, role => role.id_perfil),
              });
              this.user = user;
              this.setStorage('user', user);
              // console.log('redirect', this.redirect)
              if (this.redirect) {
                this.$router.push(this.redirect);
              }else{
                this.$router.push({ name: 'Buzzers' });
              }
            }else{
              this.toggleToast(
                true,
                this.$t('login.errors.auth_failed'),
                5000,
                false,
              );
              this.submit.disabled = this.submit.loading = false;
            }
          })
          .catch(error => {
            this.handleError(error, this.$t('errors.generic'), true);
          })
          .finally(() => {
            this.submit.disabled = this.submit.loading = false;
          });
      },

      startScene () {
        const next = _.find(this.view.scene, ['show', false]);
        if (!_.isNil(next)) {
          setTimeout(($, next) => {
            console.log(next);
            next.show = true;
            $.startScene();
          }, next.time, this, next);
        }
      }
    },

    created () {
      // if (this.user.auth.status) {
      //   console.log(this.user.auth.status);
      //   if (this.redirect) {
      //     this.$router.push(this.redirect);
      //   }else{
      //     this.$router.push('/');
      //   }
      // }
    },

    mounted () {
      this.cancelToasts();
      this.startScene();
    },

    directives: {
      mask,
    },

  }
</script>
